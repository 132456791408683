<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('security.title') }}
      </h2>
    </div>

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary dark:text-white w-1/2 sm:w-auto mr-2 ml-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary dark:text-white w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#000"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              class="table table-report sm:mt-2"
              ref="tableRef"
              id="securityTable"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap w-10">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                  <td class="w-10">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <!-- <td class="text-center flex items-center justify-center">
                    <app-list-item-image :value="row.image"></app-list-item-image>
                  </td> -->
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      <span>{{ presenter(row.unit, 'code') }}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      <span>{{ presenter(row, 'visitorName') }}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap flex justify-center"
                    >
                      <img
                        :src="row.securityString"
                        class="w-16"
                        data-action="zoom"
                      />
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                  </td>
                  <!-- <td class="table-report__action w-56" id="ignore-2">
                    <div class="flex justify-center items-center gap-4">
                      <button type="button" class="text-theme-31 font-medium" @click="showModal('addImage', row.id)">
                        <Tippy tag="div" class="flex items-center gap-1 whitespace-nowrap" :content="i18n('common.addImage')">
                          <UploadIcon class="w-4 h-4" /> {{ i18n('common.addImage') }}
                        </Tippy>
                      </button>
                      <router-link :to="`/security/${row.id}/edit`" class="text-theme-10 dark:text-theme-30 font-medium flex items-center gap-1 whitespace-nowrap">
                        <Tippy tag="div" class="flex items-center gap-1 whitespace-nowrap" :content="i18n('common.edit')">
                          <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                        </Tippy>
                      </router-link>

                      <button type="button" class="text-theme-24 font-medium" @click="showModal('delete', row.id)">
                        <Tippy tag="div" class="flex items-center gap-1 whitespace-nowrap" :content="i18n('common.delete')">
                          <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                        </Tippy>
                      </button>
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.security')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="security" @delete="doDestroy(selectedId)" />
    <!-- <AddImageModal :securityId="selectedId" @close="selectedId = null" /> -->
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import DeleteModal from '@/components/modals/delete-modal.vue'
// import QRCode from 'qrcode'
// import AddImageModal from '@/views/security/add-image-to-security-modal.vue'

export default defineComponent({
  components: {
    // AddImageModal,
    DeleteModal
  },
  setup() {
    const tableRef = ref()
    // const tabulator = ref()
    const selectedId = ref()

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selectedId,
      tableRef
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'unit',
          field: 'unit',
          label: 'security.fields.unit',
          align: 'center'
        },
        {
          name: 'visitorName',
          field: 'visitorName',
          label: 'security.fields.visitorName',
          align: 'center'
        },
        {
          name: 'qrCode',
          field: 'qrCode',
          label: 'security.fields.qrCode',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'security.fields.createdAt',
          align: 'center'
          // required: true,
        }
        // {
        //   name: 'action',
        //   field: 'action',
        //   label: 'common.actions',
        //   align: 'center'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'security/list/rows',
      pagination: 'security/list/pagination',
      listLoading: 'security/list/loading'
      // destroyLoading: 'security/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.security')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.security')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'security/list/doFetch',
      doFetchNextPage: 'security/list/doFetchNextPage',
      doFetchPreviousPage: 'security/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'security/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'security/list/doChangePaginationPageSize'
      // doDestroy: 'security/destroy/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    // generateQRCode(str) {
    //  const qr = async str => await QRCode.toDataURL(str)
    //  return qr(str)
    // },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterPrice(price) {
      const currency = this.language == 'ar' ? 'جنيه' : 'EGP'
      return `${price} ${currency}`
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'securityTable', 'security')
    },
    onDownloadPdf() {
      $h.downloadPdf('#securityTable', 'security', [3])
    },
    onPrint() {
      print({
        printable: 'securityTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 900,
        ignoreElements: ['ignore-1', 'ignore-2']
      })
    },
    // showModal(modalName, id) {
    //   this.selectedId = id
    //   switch (modalName) {
    //     case 'delete':
    //       cash('#delete-modal-preview').modal('show')
    //       break
    //     case 'addImage':
    //       cash('#add-image-to-security-modal').modal('show')
    //       break
    //   }
    // },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        pagination: {
          firstPage: true,
          limit: 15,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
